import * as Yup from "yup";

// Define the valid types for the 'type' field
const validTypes = ['1', '2']; // Assuming '1' is deposit and '2' is withdraw

export const IframeValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(/^[A-Za-z\s]+$/, "Name must contain only letters and spaces")
    .required("Name is required"),
  amount: Yup.string()
    .trim()
    .matches(/^\d+(\.\d+)?$/, "Amount must be a valid number")
    .required("Amount is required"),
  userId: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, "userId must contain only number")
    .required("UserId is required"),
  currencyId: Yup.string()
    .required("Currency is required"),
  method_id: Yup.string()
    .required("Payment method is required"),
  type: Yup.string()
    .oneOf(validTypes, "Type must be '1' (deposit) or '2' (withdraw)")
    .required("Type is required"),
});

import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import './App.css';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { useMemo, useState } from 'react';
import Ifram from './Ifram';
import { IframeValidation } from './validation/validate';
import { toast } from "react-toastify";
import jwt from 'jsonwebtoken';
import * as jose from 'jose'
import crypto from 'crypto-browserify';

function App() {
  const [currencyData, setCurrencyData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [paymentType, setPaymentType] = useState("");

  const createIframe = useMutation(
    async (values) => {
      try {
        const createIframeUrl = `${process.env.REACT_APP_IFRAME_URL}create-iframe`;
        const response = await axios.post(createIframeUrl, values, {
          headers: { 'signature_token': values.jwtToken, 'api_key': values.api_key },
        });

        const { result, status } = response.data;
        if (status) {
          const windowWidth = 1000;
          const windowHeight = 800;

          const left = (window.screen.width - windowWidth) / 2;
          const top = (window.screen.height - windowHeight) / 2;
          const windowFeatures = `width=${windowWidth},height=${windowHeight},scrollbars=yes,resizable=yes,left=${left},top=${top}`;

          const miniWindow = window.open(result.redirect_url, 'MiniBrowserWindow', windowFeatures);
          if (!miniWindow) {
            console.error('Failed to open mini browser window. It might be blocked by the browser\'s pop-up blocker.');
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error || 'An error occurred';
        console.log(errorMessage);
        toast.error(errorMessage);
      }
    }
  );


  const formikConfig = {
    initialValues: {
      amount: '',
      type: '',
      merchant_id: '662f7313bbc8060fc5a8ebc6',
      userId: '',
      method_id: '',
      name: '',
      jwtToken: '',
      api_key: 'Yx0j2n~aNK77oQSfn',
    },
    validationSchema: IframeValidation,
    onSubmit: async (values) => {
      const signatureString = `${values?.merchant_id}#${values?.api_key}#${values.name}`;
      const issuedAt = Date.now();
      const expiration = issuedAt + (60 * 60 * 1000);  // Expiration time in milliseconds (60 minutes later)
      const payload = {
        url: 'create-iframe',
        iat: issuedAt,
        exp: expiration,
        key: "kOryUm-Jcf9-lCBFp-xZLvmI",
        signature: crypto.createHash("sha256").update(JSON.stringify(signatureString)).digest().toString("hex")
      };
      let jwtToken = jwt.sign(payload, 'd9dd4f61e399f95692eeacb0c819f1b6da319ee3e6a543350e4d1335b08eb669d1ef4a0da4db72f8431cc6fd1a929616d7d75eab7beb4cdfd814562605e170d7');

      values.jwtToken = jwtToken
      await createIframe.mutateAsync(values);
    },
  };

  const formikDeposit = useFormik(formikConfig);
  const queryKeys = useMemo(() => ["getCurrency"], []);
  const { data: currency } = useQuery(queryKeys,
    async () => {
      try {
        const values = {
          merchant_id: '662f7313bbc8060fc5a8ebc6',
          api_key: 'Yx0j2n~aNK77oQSfn',
          secret_key: 'd9dd4f61e399f95692eeacb0c819f1b6da319ee3e6a543350e4d1335b08eb669d1ef4a0da4db72f8431cc6fd1a929616d7d75eab7beb4cdfd814562605e170d7',
        };
        const signatureString = `${values?.merchant_id}#${values?.api_key}#${values.secret_key}`;

        const issuedAt = Date.now();
        const expiration = issuedAt + (60 * 60 * 1000);  // Expiration time in milliseconds (60 minutes later)
        const payload = {
          url: 'get-currency',
          iat: issuedAt,
          exp: expiration,
          key: "kOryUm-Jcf9-lCBFp-xZLvmI",
          signature: crypto.createHash("sha256").update(JSON.stringify(signatureString)).digest().toString("hex")
        };

        let jwtToken = jwt.sign(payload, process.env.REACT_APP_JWT_KEY);
        const getCurrencyUrl = `${process.env.REACT_APP_IFRAME_URL}get-currency`;
        const response = await axios.post(getCurrencyUrl, values, {
          headers: {
            'signature_token': jwtToken,
          },
        });
        setPaymentData([]);
        setCurrencyData(response.data);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.error);
      }

    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const paymentGatewayKeys = useMemo(() => ["getPaymentGateway", paymentType], [paymentType]);
  const { data: paymentGateway } = useQuery(paymentGatewayKeys,
    async () => {
      try {
        const values = {
          merchant_id: '662f7313bbc8060fc5a8ebc6',
          api_key: 'Yx0j2n~aNK77oQSfn',
          type: paymentType,
          secret_key: 'd9dd4f61e399f95692eeacb0c819f1b6da319ee3e6a543350e4d1335b08eb669d1ef4a0da4db72f8431cc6fd1a929616d7d75eab7beb4cdfd814562605e170d7',
        };
        const signatureString = `${values?.merchant_id}#${values?.api_key}#${values.secret_key}`;

        const issuedAt = Date.now();
        const expiration = issuedAt + (60 * 60 * 1000);  // Expiration time in milliseconds (60 minutes later)
        const payload = {
          url: 'get-payment-gateway',
          iat: issuedAt,
          exp: expiration,
          key: "kOryUm-Jcf9-lCBFp-xZLvmI",
          signature: crypto.createHash("sha256").update(JSON.stringify(signatureString)).digest().toString("hex")
        };

        let jwtToken = jwt.sign(payload, process.env.REACT_APP_JWT_KEY);
        const getCurrencyUrl = `${process.env.REACT_APP_IFRAME_URL}get-payment-gateway`;
        const response = await axios.post(getCurrencyUrl, values, {
          headers: {
            'signature_token': jwtToken,
          },
        });
        setPaymentData(response.data);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.error);
      }

    },
    {
      enabled: !!paymentType,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return (
    <div className="row justify-content-center mt-5">
      <div className="col-md-4">
        <div className="card shadow-lg mb-4">
          <div className="card-header bg-primary text-white text-center">
            <h4 className="mb-0">Deposit / Withdraw</h4>
          </div>
          <div className="card-body p-4" style={{ backgroundColor: '#f8f9fa' }}>
            <FormikProvider value={formikDeposit}>
              <Form onSubmit={formikDeposit.handleSubmit}>
                <FormGroup>
                  <Label htmlFor="depositId" className="form-label">
                    User Id
                  </Label>
                  <Input
                    id="depositId"
                    type="text"
                    className="form-control"
                    {...formikDeposit.getFieldProps('userId')}
                  />
                  <ErrorMessage name="userId" component="span" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="depositName" className="form-label">
                    Name
                  </Label>
                  <Input
                    id="depositName"
                    type="text"
                    className="form-control"
                    {...formikDeposit.getFieldProps('name')}
                  />
                  <ErrorMessage name="name" component="span" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="depositAmount" className="form-label">
                    Amount
                  </Label>
                  <Input
                    id="depositAmount"
                    type="number"
                    className="form-control"
                    {...formikDeposit.getFieldProps('amount')}
                  />
                  <ErrorMessage name="amount" component="span" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="depositType" className="form-label">
                    Type
                  </Label>
                  <Field
                    as="select"
                    id="depositType"
                    className="form-select"
                    {...formikDeposit.getFieldProps('type')}
                    onChange={(event) => {
                      formikDeposit.handleChange(event);
                      setPaymentType(event.target.value);
                    }}
                  >
                    <option value="">Select Type</option>
                    <option value="1">Deposit</option>
                    <option value="2">Withdraw</option>
                  </Field>
                  <ErrorMessage name="type" component="span" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="currencyId" className="form-label">
                    Currency
                  </Label>
                  <Field
                    as="select"
                    id="currencyId"
                    className="form-select"
                    {...formikDeposit.getFieldProps('currencyId')}
                  >
                    <option value="">Select Currency</option>
                    {currencyData?.map((currency) => (
                      <option key={currency._id} value={currency._id}>
                        {currency.currency_name} ({currency.currency_code}) - {currency.symbol}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="currencyId" component="span" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="method_id" className="form-label">
                    Payment method
                  </Label>
                  <Field
                    as="select"
                    id="method_id"
                    className="form-select"
                    {...formikDeposit.getFieldProps('method_id')}
                  >
                    <option value="">Select Payment Method</option>
                    {paymentData?.map((payment) => (
                      <option key={payment.id} value={payment.id}>
                        {payment.title}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="method_id" component="span" className="text-danger" />
                </FormGroup>
                <Button color="primary" type="submit" className="w-100 btn-lg">
                  Submit
                </Button>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
